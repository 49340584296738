import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "table-responsive theme-scrollbar" }
const _hoisted_9 = { class: "table table-dashed" }
const _hoisted_10 = { class: "d-flex gap-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "items-center align-top" }
const _hoisted_13 = { class: "items-right align-top" }

import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetDisputes } from "@/services/ApiDispute";
// Define refs to hold rule data and current pagination state

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexDisputes',
  setup(__props) {

const allDisputes = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const CreateDispute = defineAsyncComponent(
  () => import("@/components/modal/CreateDispute.vue")
);
// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(CreateDispute, {})
    .then(() => {
      // Reload rules after editing to reflect changes
      loadDisputes();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}
function handleView(ID: any) {
  router.push(`/app/disputes/view/${ID}`);
}

function loadDisputes() {
  (async () => {
    try {
      const { rows } = await GetDisputes("all", 1, {});
      allDisputes.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadDisputes();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Disputes")
          ], -1)),
          _createElementVNode("div", { class: "col-sm-6 col-12 d-flex gap-2 justify-content-end" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: handleCreate
            }, " Create Dispute ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("table", _hoisted_9, [
                      _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }),
                          _createElementVNode("th", { scope: "col" }, "Case Number"),
                          _createElementVNode("th", { scope: "col" }, "Date"),
                          _createElementVNode("th", { scope: "col" }, "Status"),
                          _createElementVNode("th", { scope: "col" }, "Name"),
                          _createElementVNode("th", { scope: "col" }, "Client"),
                          _createElementVNode("th", { scope: "col" }, "Event"),
                          _createElementVNode("th", { scope: "col" }, "Amount")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allDisputes.value, (dispute, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `dispute-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: ($event: any) => (handleView(dispute.ID))
                                }, " View ", 8, _hoisted_11)
                              ])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(dispute.case_number), 1),
                            _cache[1] || (_cache[1] = _createElementVNode("td", { class: "items-center align-top" }, null, -1)),
                            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "items-center align-top" }, null, -1)),
                            _createElementVNode("td", null, _toDisplayString(dispute.name), 1),
                            _createElementVNode("td", null, _toDisplayString(dispute.client), 1),
                            _createElementVNode("td", _hoisted_12, _toDisplayString(dispute.event), 1),
                            _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.$filters.currency(dispute.amount)), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})