<template>
  <Breadcrumbs
    main="AWS Dashboard"
    defaultdesc="true"
    mains="AWS Dashboard"
    title="Default"
  />
  <div class="container-fluid dashboard-3">
    <div class="row">
      <div class="col-xxl-3 col-xl-4 col-md-7 col-sm-6 box-col-4">
        <div class="d-flex flex-column">
          <IAMUser @on-reload="handleOnIAMUserReload" />
          <InstanceType @on-reload="handleOnReload" />
        </div>
      </div>
      <Region @on-reload="handleOnReload" />
      <CostView @on-reload="handleOnReload" />
    </div>

    <template v-if="all_instances.length > 0">
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive theme-scrollbar">
            <table class="table table-dashed">
              <thead>
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Region</th>
                  <th scope="col">Instance Id</th>
                  <th scope="col">Instance Type</th>
                  <th scope="col">State</th>
                  <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(instance, index) in all_instances"
                  :key="`instance-${index}`"
                  class="even:bg-gray-50 odd:bg-white"
                >
                  <td>
                    <div class="d-flex gap-2">
                      <button
                        type="button"
                        @click.stop="
                          handleTerminate(instance.region, instance.instanceId)
                        "
                        class="btn btn-sm btn-danger text-light btn-action"
                      >
                        Terminate
                      </button>
                    </div>
                  </td>
                  <td>{{ instance.region }}</td>
                  <td>
                    <div class="flex flex-col flex-column">
                      <div>
                        <span>{{ instance.instanceId }}</span>
                      </div>
                      <div class="cursor-pointer" @click="copyIP(instance.instanceIP)">
                        <span class="badge badge-primary p-2">{{
                          instance.instanceIP
                        }}</span>
                      </div>
                    </div>
                  </td>
                  <td>{{ instance.instanceType }}</td>
                  <td>{{ instance.instanceState }}</td>
                  <td>{{ instance.instanceName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
  font-size: 18px;
}
table,
th,
td {
  border: 1px solid black;
}
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;
  &:hover {
    border: 1px solid #a20000;
  }
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>
<script lang="ts" setup>
import { GetIAMUsers, GetEC2Dashboard, TerminateInstance } from "@/services/ApiCore";
import { defineAsyncComponent, onMounted, ref } from "vue";
import { openModal } from "@kolirt/vue-modal";
const Region = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/Region.vue")
);
const InstanceType = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/InstanceType.vue")
);
const IAMUser = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/IAMUser.vue")
);
const CostView = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/CostView.vue")
);
import { useDashboardStore } from "@/store/dashboard";
const store = useDashboardStore();
import { useCommonStore } from "@/store/common";
const commonStore = useCommonStore();
import { toast } from "vue3-toastify";
let emails = ref<any>([]);
let all_instances = ref<any>([]);
onMounted(() => {
  (async () => {
    commonStore.toggleIAMUserLoader();
    const { users } = await GetIAMUsers();
    setTimeout(() => {
      store.setIAMUsers(users);
    }, 2000);
    commonStore.toggleLoader();
    const { regions, instanceTypes, instances } = await GetEC2Dashboard();
    all_instances.value = instances;
    setTimeout(() => {
      store.setEC2Dashboard(regions, instanceTypes);
    }, 2000);
  })();
});
async function handleTerminate(region: string, instanceId: string) {
  const { result } = await TerminateInstance(region, instanceId);
  if (result == "success") {
    all_instances.value = all_instances.value.filter((x) => x.instanceId != instanceId);
  } else {
    toast.danger("Terminate Failed", {
      position: "top-right",
      autoClose: 2000,
    });
  }
}
function handleOnIAMUserReload() {
  (async () => {
    commonStore.toggleIAMUserLoader();
    const { users } = await GetIAMUsers();
    setTimeout(() => {
      store.setIAMUsers(users);
    }, 2000);
  })();
}
function copyIP(ip: string) {
  const el = document.createElement("textarea");
  el.value = ip;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Copied", {
    position: "top-right",
    autoClose: 2000,
  });
}
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const { regions, instanceTypes } = await GetEC2Dashboard();
    setTimeout(() => {
      store.setEC2Dashboard(regions, instanceTypes);
    }, 2000);
  })();
}
</script>
