import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header row" }
const _hoisted_2 = { class: "page-body-wrapper" }
const _hoisted_3 = ["data-sidebar-layout"]
const _hoisted_4 = { class: "page-body" }

import { defineAsyncComponent, ref, onMounted, watch } from "vue";
import { useMenuStore } from "@/store/menu";
import { uselayoutStore } from "@/store/layout";
import { storeToRefs } from "pinia";

export default /*@__PURE__*/_defineComponent({
  __name: 'BodyView',
  setup(__props) {

const TapTop = defineAsyncComponent(() => import("@/layout/TapTop.vue"));
const FooterView = defineAsyncComponent(
  () => import("@/components/common/block/FooterView.vue")
);
const Sidebar = defineAsyncComponent(
  () => import("@/components/common/block/sidebar/indexSidebar.vue")
);
const Header = defineAsyncComponent(
  () => import("@/components/common/block/header/indexHeader.vue")
);
const Customizer = defineAsyncComponent(
  () => import("@/components/common/block/customizer/IndexCustomizer.vue")
);
let sidebar_toggle_var = ref<boolean>(false);
let display = ref<boolean>(false);
let layoutobj = ref<any>({});
let storeLayout = uselayoutStore();
// const tokenizerScript = document.createElement("script");
// tokenizerScript.setAttribute(
//   "src",
//   "https://editor.unlayer.com/embed.js"
// );
// tokenizerScript.setAttribute("language", "javascript");
// document.head.appendChild(tokenizerScript);

const store = useMenuStore();
const { layouts: layouts } = storeToRefs(storeLayout);
watch(
  () => layouts,
  () => {
    layoutobj.value = storeLayout.layouts.settings.sidebar_setting;
  },
  { deep: true }
);
watch(
  () => "router",
  () => {
    if (window.innerWidth < 991 && storeLayout.layouts.settings.layout === "Horizontal") {
      const newlayout = JSON.parse(
        JSON.stringify(layoutobj).replace("horizontal-sidebar", "compact-wrapper")
      );
      layoutobj.value = JSON.parse(JSON.stringify(newlayout))[
        storeLayout.layouts.settings.layout
      ];
    } else {
      layoutobj.value = JSON.parse(JSON.stringify(layoutobj))[
        storeLayout.layouts.settings.layout
      ];
    }
  }
);
function sidebar_toggle(value: boolean) {
  sidebar_toggle_var.value = !value;
}
function handleScroll() {
  if (window.innerWidth <= 1199) {
    display.value = true;
    store.togglesidebar = false;
  } else {
    store.togglesidebar = true;
    display.value = false;
  }
}
onMounted(() => {
  window.addEventListener("resize", handleScroll);
  layoutobj.value = storeLayout.layouts.settings.sidebar_setting;
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(TapTop)),
    _createElementVNode("div", {
      class: _normalizeClass(["page-wrapper", [
      !_unref(store).togglesidebar ? 'sidebar-open' : '',
      _unref(display) ? 'compact-wrapper ' : _unref(layoutobj),
    ]]),
      id: "pageWrapper"
    }, [
      _createElementVNode("header", _hoisted_1, [
        _createVNode(_unref(Header), { onClick: sidebar_toggle })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overlay" }, null, -1)),
        _createElementVNode("aside", {
          class: _normalizeClass(["page-sidebar", _unref(storeLayout).svg == 'stroke-svg' ? '' : 'iconcolor-sidebar']),
          "data-sidebar-layout": 
          _unref(storeLayout).svg == 'stroke-svg' ? 'stroke-svg' : 'iconcolor-sidebar'
        
        }, [
          _createVNode(_unref(Sidebar))
        ], 10, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createVNode(_unref(FooterView))
    ], 2),
    _createVNode(_unref(Customizer))
  ], 64))
}
}

})