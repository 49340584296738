import ApiInstance from "./ApiBase";

export const GetAssignments = async (): Promise<any> => {
    const res = await ApiInstance.get(`/assignments`);
    return res?.data;
};

export const GetAssignment = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/assignment/${id}`);
    return res?.data;
};
export const GetGlobalClient = async (): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getGlobalClient`);
    return res?.data;
};
export const GetGlobalEvent = async (client: string): Promise<any> => {
    const res = await ApiInstance.get(`https://api.easyreg2023.ca/api/getGlobalEvent/${client}`);
    return res?.data;
};


export const ManageAssignments = async (
    assignment_id: any,
    action: string,
    assignment_email: string = '',
    assignment_firstname: string = '',
    assignment_lastname: string = '',
    assignment_client: string = '',
    assignment_event: string = '',
    assignment_client_abb: string = '',
    assignment_client_name: string = '',
    assignment_event_name: string = '',
    assignment_event_type: string = '',
): Promise<any> => {
    const data = new FormData();
    data.append("assignment_id", assignment_id);
    data.append("action", action);
    data.append("assignment_firstname", assignment_firstname);
    data.append("assignment_lastname", assignment_lastname);
    data.append("assignment_email", assignment_email);
    data.append("assignment_client", assignment_client);
    data.append("assignment_event", assignment_event);
    data.append("assignment_client_abb", assignment_client_abb);
    data.append("assignment_client_name", assignment_client_name);
    data.append("assignment_event_name", assignment_event_name);
    data.append("assignment_event_type", assignment_event_type);
    const res = await ApiInstance.post(`/manage-assignment`, data);
    return res?.data;
};
