<template>
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Edit Template Design [{{ template_description }}]</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex justify-content-end gap-2">
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click.stop="handleCancel"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click.stop="handleSave"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="editor-wrapper">
        <div id="editor-container" class="w-full"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#editor-container::v-deep {
  height: 735px !important;
}
.editor-wrapper {
  height: 735px !important;
}
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;
  &:hover {
    border: 1px solid #a20000;
  }
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>
<script lang="ts" setup>
import { defineAsyncComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { GetEmailByTemplate, SaveTemplateDesign } from "@/services/ApiCore";
import { useRoute, useRouter } from "vue-router";
let currentJson = ref<string>("");
let currentHtml = ref<string>("");
let template_description = ref<string>("");
let topolPluginInit = ref<boolean>(false);
import TopolPlugin from "@topol.io/editor";
let router = useRouter();
const route = useRoute();
let sendingEmail = ref<boolean>(false);
watch(
  () => route.params.template_id,
  async (templateID) => {
    initEditor();
  }
);
function handleCancel() {
  router.push(`/administration/templates-easyreg/list`);
}
function handleSave() {
  sendingEmail.value = true;
  TopolPlugin.save();
  // unlayer.exportHtml(async (data: any) => {
  //   var json = data.design; // design json
  //   var html = data.html; // design html

  //   const foundIndex = json.body.rows.findIndex(
  //     (x: any) => x.id == "maincontent"
  //   );
  //   let mainHtmlText =
  //     json.body.rows[foundIndex].columns[0].contents[0].values.text;

  //   console.log("json", json);
  //   // Save the json, or html here
  //   await SaveTemplateDesign(
  //     JSON.stringify(json),
  //     mainHtmlText,
  //     html,
  //     route.params.template_id.toString()
  //   );
  //   router.push(`/administration/templates-easyreg/list`);
  // });
}

function loadEditor() {
  try {
    if (topolPluginInit.value) {
      TopolPlugin.destroy();
    }
    const TOPOL_OPTIONS = {
      defaultTemplateSettings: {
        emailWidth: 660,
      },
      id: "#editor-container",
      htmlMinified: true,
      authorize: {
        apiKey: process.env.TOPOL_API_KEY,
        userId: 1,
      },
      disableAlerts: true,
      light: true,
      premadeBlocks: [
        {
          name: "Online Attendee Site",
          blocks: [
            {
              img:
                "https://2023-easyreg-prod.s3.ca-central-1.amazonaws.com/attachments/online_attendee_site_premade_block.png", // Image url, for best experience use width > 330 px
              name: "Online Attendee Site Block", // Or name if not image available
              // MJML JSON
              definition: [
                {
                  tagName: "mj-section",
                  attributes: {
                    "full-width": "620px",
                    padding: "10px 0px 10px 0px",
                    "mj-class": "section",
                  },
                  type: null,
                  children: [
                    {
                      tagName: "mj-column",
                      attributes: {
                        width: "100%",
                        "vertical-align": "top",
                      },
                      children: [
                        {
                          tagName: "mj-text",
                          content:
                            '<p>You can access the Online Attendee Site using the following link:<br>###attendee.accesslink###</p>\n          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">OR</p>\n          <ul>\n              <li>Go to https://online.ourconference.events</li>\n              <li>Enter ###attendee.email### under Email Address</li>\n              <li>Enter ###attendee.code### under Code</li>\n              <li>Click Login</li>\n          </ul>',
                          attributes: {
                            padding: "10px 10px",
                            align: "left",
                            "line-height": 1.3,
                            containerWidth: 620,
                          },
                          uid: "H1lqIiX4lm",
                        },
                      ],
                      uid: "SJ3I0XVx7",
                    },
                  ],
                  layout: 1,
                  backgroundColor: "",
                  backgroundImage: "",
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  uid: "rkqIjQNe7",
                },
              ],
            },
          ],
        },
      ],
      customBlocks: [],
      callbacks: {
        onInit(json: any, html: any) {
          topolPluginInit.value = true;
        },
        async onSave(json: any, html: any) {
          currentJson.value = json;
          currentHtml.value = html;
          if (sendingEmail.value) {
            await SaveTemplateDesign(
              JSON.stringify(json),
              "",
              html,
              route.params.template_id.toString()
            );
            sendingEmail.value = false;
            router.push(`/administration/templates-easyreg/list`);
          }
        },
        onLoaded(json: any, html: any) {
          TopolPlugin.save();
        },
      },
    };
    TopolPlugin.init(TOPOL_OPTIONS);
  } catch (err) {
    console.log("load editor", err);
  }
}
function loadDesign(json: any) {
  if (json) {
    TopolPlugin.load(json);
  }
}
function initEditor() {
  (async () => {
    try {
      loadEditor();
      const { json, html, subject, description } = await GetEmailByTemplate(
        route.params.template_id.toString()
      );
      template_description.value = description;
      currentJson.value = json;
      currentHtml.value = html;
      setTimeout(() => {
        loadDesign(json);
      }, 500);
    } catch (e) {
      console.log("onMounted error", e);
    }
  })();
}
onUnmounted(() => {
  if (topolPluginInit.value) {
    console.log('--------------');
    TopolPlugin.destroy();
  }
});
onMounted(() => {
  (async () => {
    sendingEmail.value = false;
    console.log("route.params.template_id", route.params.template_id);
    initEditor();
  })();
});
</script>
