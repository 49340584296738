import ApiInstance from "./ApiBase";

export const GetTinyURLs = async (): Promise<any> => {
    const res = await ApiInstance.get(`/tinyurls`);
    return res?.data;
};

export const GetTinyURL = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/tinyurl/${id}`);
    return res?.data;
};

export const ManageTinyURLs = async (tinyurl_id: any, tinyurl_short: string, tinyurl_keywords: string, tinyurl_description: string, action: string): Promise<any> => {
    const data = new FormData();
    data.append("tinyurl_id", tinyurl_id);
    data.append("action", action);
    data.append("tinyurl_short", tinyurl_short);
    data.append("tinyurl_keywords", tinyurl_keywords);
    data.append("tinyurl_description", tinyurl_description);
    const res = await ApiInstance.post(`/manage-tinyurl`, data);
    return res?.data;
};
