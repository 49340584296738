import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid dashboard-3" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xxl-3 col-xl-4 col-md-7 col-sm-6 box-col-4" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col-sm-12 col-lg-12 col-xl-12" }
const _hoisted_7 = { class: "table-responsive theme-scrollbar" }
const _hoisted_8 = { class: "table table-dashed" }
const _hoisted_9 = { class: "d-flex gap-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex flex-col flex-column" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "badge badge-primary p-2" }

import { GetIAMUsers, GetEC2Dashboard, TerminateInstance } from "@/services/ApiCore";
import { defineAsyncComponent, onMounted, ref } from "vue";
import { openModal } from "@kolirt/vue-modal";
import { useDashboardStore } from "@/store/dashboard";
import { useCommonStore } from "@/store/common";
import { toast } from "vue3-toastify";

export default /*@__PURE__*/_defineComponent({
  __name: 'EC2Default',
  setup(__props) {

const Region = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/Region.vue")
);
const InstanceType = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/InstanceType.vue")
);
const IAMUser = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/IAMUser.vue")
);
const CostView = defineAsyncComponent(
  () => import("@/components/theme/dashboards/ec2_default/CostView.vue")
);
const store = useDashboardStore();
const commonStore = useCommonStore();
let emails = ref<any>([]);
let all_instances = ref<any>([]);
onMounted(() => {
  (async () => {
    commonStore.toggleIAMUserLoader();
    const { users } = await GetIAMUsers();
    setTimeout(() => {
      store.setIAMUsers(users);
    }, 2000);
    commonStore.toggleLoader();
    const { regions, instanceTypes, instances } = await GetEC2Dashboard();
    all_instances.value = instances;
    setTimeout(() => {
      store.setEC2Dashboard(regions, instanceTypes);
    }, 2000);
  })();
});
async function handleTerminate(region: string, instanceId: string) {
  const { result } = await TerminateInstance(region, instanceId);
  if (result == "success") {
    all_instances.value = all_instances.value.filter((x) => x.instanceId != instanceId);
  } else {
    toast.danger("Terminate Failed", {
      position: "top-right",
      autoClose: 2000,
    });
  }
}
function handleOnIAMUserReload() {
  (async () => {
    commonStore.toggleIAMUserLoader();
    const { users } = await GetIAMUsers();
    setTimeout(() => {
      store.setIAMUsers(users);
    }, 2000);
  })();
}
function copyIP(ip: string) {
  const el = document.createElement("textarea");
  el.value = ip;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  toast.success("Copied", {
    position: "top-right",
    autoClose: 2000,
  });
}
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const { regions, instanceTypes } = await GetEC2Dashboard();
    setTimeout(() => {
      store.setEC2Dashboard(regions, instanceTypes);
    }, 2000);
  })();
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "AWS Dashboard",
      defaultdesc: "true",
      mains: "AWS Dashboard",
      title: "Default"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(IAMUser), { onOnReload: handleOnIAMUserReload }),
            _createVNode(_unref(InstanceType), { onOnReload: handleOnReload })
          ])
        ]),
        _createVNode(_unref(Region), { onOnReload: handleOnReload }),
        _createVNode(_unref(CostView), { onOnReload: handleOnReload })
      ]),
      (_unref(all_instances).length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("table", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", { scope: "col" }, "Action"),
                      _createElementVNode("th", { scope: "col" }, "Region"),
                      _createElementVNode("th", { scope: "col" }, "Instance Id"),
                      _createElementVNode("th", { scope: "col" }, "Instance Type"),
                      _createElementVNode("th", { scope: "col" }, "State"),
                      _createElementVNode("th", { scope: "col" }, "Name")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(all_instances), (instance, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `instance-${index}`,
                        class: "even:bg-gray-50 odd:bg-white"
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("button", {
                              type: "button",
                              onClick: _withModifiers(($event: any) => (
                          handleTerminate(instance.region, instance.instanceId)
                        ), ["stop"]),
                              class: "btn btn-sm btn-danger text-light btn-action"
                            }, " Terminate ", 8, _hoisted_10)
                          ])
                        ]),
                        _createElementVNode("td", null, _toDisplayString(instance.region), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", null, [
                              _createElementVNode("span", null, _toDisplayString(instance.instanceId), 1)
                            ]),
                            _createElementVNode("div", {
                              class: "cursor-pointer",
                              onClick: ($event: any) => (copyIP(instance.instanceIP))
                            }, [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(instance.instanceIP), 1)
                            ], 8, _hoisted_12)
                          ])
                        ]),
                        _createElementVNode("td", null, _toDisplayString(instance.instanceType), 1),
                        _createElementVNode("td", null, _toDisplayString(instance.instanceState), 1),
                        _createElementVNode("td", null, _toDisplayString(instance.instanceName), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})