import ApiInstance from "./ApiBase";


export const GetDisputes = async (type: string, keyword: any, filters: any): Promise<any> => {


    const filterKeys: string[] = Object.keys(filters);
    let queryArr: string[] = [];
    queryArr.push(`type=${type}`);
    queryArr.push(`keyword=${keyword}`);

    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (filters[key] != '') {
                const query = key + "=" + filters[key];
                queryArr.push(query);
            }
        });
    }

    let query = queryArr.length > 0 ? "?" + queryArr.join("&") : "";
    const res = await ApiInstance.get(`/disputes${query}`);
    return res?.data;
};

export const GetDispute = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/dispute/${id}`);
    return res?.data;
};


export const GetUserAccountList = async (code: string, client: string, event: string): Promise<any> => {
    const data = new FormData();
    data.append("code", code);
    data.append("client", client);
    data.append("event", event);
    const res = await ApiInstance.post(`https://api.easyreg2023.ca/api/getUserAccountList`, data);
    return res?.data;
};

export const CreateDispute = async (type: string, code: string, case_number: string, amount: string, date: string): Promise<any> => {
    const data = new FormData();
    data.append("type", type);
    data.append("code", code);
    data.append("case_number", case_number);
    data.append("amount", amount);
    data.append("date", date);
    const res = await ApiInstance.post(`/create-dispute`, data);
    return res?.data;
};

export const CreateManualDispute = async (dispute_code: string, dispute_case_number: string, dispute_amount: string, dispute_attendee_name: string, dispute_attendee_email: string, dispute_attendee_phone: string, dispute_client_code: string, dispute_client_name: string, dispute_event_code: string, dispute_event_name: string, dispute_transactionID: string, dispute_card_number: string, dispute_card_type: string): Promise<any> => {
    const data = new FormData();
    data.append("dispute_code", dispute_code);
    data.append("dispute_case_number", dispute_case_number);
    data.append("dispute_amount", dispute_amount);
    data.append("dispute_attendee_name", dispute_attendee_name);
    data.append("dispute_attendee_email", dispute_attendee_email);
    data.append("dispute_attendee_phone", dispute_attendee_phone);
    data.append("dispute_client_code", dispute_client_code);
    data.append("dispute_client_name", dispute_client_name);
    data.append("dispute_event_code", dispute_event_code);
    data.append("dispute_event_name", dispute_event_name);
    data.append("dispute_transactionID", dispute_transactionID);
    data.append("dispute_card_number", dispute_card_number);
    data.append("dispute_card_type", dispute_card_type);
    const res = await ApiInstance.post(`/create-manual-dispute`, data);
    return res?.data;
};



export const CreateDisputeRecord = async (dispute_id: any, action_date: string, action_action: string, action_comment: string, action_file_name: string, action_file_path: string): Promise<any> => {
    const data = new FormData();
    data.append("dispute_id", dispute_id);
    data.append("action_date", action_date);
    data.append("action_action", action_action);
    data.append("action_comment", action_comment);
    data.append("action_file_name", action_file_name);
    data.append("action_file_path", action_file_path);
    const res = await ApiInstance.post(`/create-dispute-record`, data);
    return res?.data;
};

export const GetDisputeEmailByTemplate = async (dispute_id: any, code: string, client: string, event: string): Promise<any> => {
    const data = new FormData();
    data.append("dispute_id", dispute_id);
    data.append("code", code);
    data.append("client", client);
    data.append("event", event);
    const res = await ApiInstance.post(`/get-dispute-email-by-template`, data);
    return res?.data;
};
export const UpdateDisputeRecord = async (dispute_id: any): Promise<any> => {
    const data = new FormData();
    data.append("dispute_id", dispute_id);
    const res = await ApiInstance.post(`/update-dispute-record`, data);
    return res?.data;
};



