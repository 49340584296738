<template>
  <Breadcrumbs main="Dashboard" defaultdesc="true" mains="Dashboard" title="Default" />
  <div class="container-fluid dashboard-3">
    <div class="row">
      <Registration @on-reload="handleOnReload" />
      <Email
        @on-reload="handleOnReload"
        @on-email-queue="handleOnEmailQueue"
        @on-mailgun-failed="handleOnMailGunFailedQueue"
      />
      <SMS @on-reload="handleOnReload" @on-sms-queue="handleOnSMSQueue" />
      <Elavon @on-reload="handleOnReload" />
    </div>
    <template v-if="emails.length > 0">
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive theme-scrollbar">
            <table class="table table-dashed">
              <thead>
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Subject</th>
                  <th scope="col">From Email</th>
                  <th scope="col">From Name</th>
                  <th scope="col">To Email</th>
                  <th scope="col">To Name</th>
                  <th scope="col">Client</th>
                  <th scope="col">Event</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(email, index) in emails" :key="`email-${index}`">
                  <td>
                    <div class="d-flex gap-2">
                      <button
                        type="button"
                        @click.stop="handleEdit('email', email.ID, '')"
                        class="btn btn-sm btn-light text-dark btn-action"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        @click.stop="handleResend('email', email.ID, '')"
                        class="btn btn-sm btn-light text-dark btn-action"
                      >
                        Resend
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger text-white btn-action"
                        @click.stop="handleDelete('email', email.ID, '')"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                  <td>{{ email.queue_email_subject }}</td>
                  <td>{{ email.queue_email_from_email }}</td>
                  <td>{{ email.queue_email_from_name }}</td>
                  <td>{{ email.queue_email_to_email }}</td>
                  <td>{{ email.queue_email_to_name }}</td>
                  <td>{{ email.queue_email_client }}</td>
                  <td>{{ email.queue_email_event }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template v-if="mailguns.length > 0">
      <div class="row" style="height: 400px; overflow-y: scroll">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="">
            <table style="width: 100%" cellspacing="2" cellpadding="2">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Action</th>
                  <th scope="col" style="width: 5%">Date</th>
                  <th scope="col" style="width: 10%">To Email</th>
                  <th scope="col" style="width: 10%">Subject</th>
                  <th scope="col" style="width: 7%">Reason</th>
                  <th scope="col" style="width: 10%">From Email</th>
                  <!-- <th scope="col" style="width: 10%">From Name</th> -->
                  <!-- <th scope="col" style="width: 10%">To Name</th> -->
                  <th scope="col" style="width: 14%">Client</th>
                  <th scope="col" style="width: 14%">Event</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(mailgun, index) in mailguns" :key="`mailgun-${index}`">
                  <td style="width: 10%">
                    <div class="d-flex gap-2">
                      <button
                        type="button"
                        class="btn btn-sm btn-light text-dark btn-action"
                        @click.stop="
                          handleEdit('email_failed', mailgun.ID, mailgun.failed_ID)
                        "
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        @click.stop="
                          handleResend('email_mailgun', mailgun.ID, mailgun.failed_ID)
                        "
                        class="btn btn-sm btn-light text-dark btn-action"
                      >
                        Mailgun
                      </button>
                      <button
                        type="button"
                        @click.stop="
                          handleResend('email_sendgrid', mailgun.ID, mailgun.failed_ID)
                        "
                        class="btn btn-sm btn-light text-dark btn-action"
                      >
                        SendGrid
                      </button>
                    </div>
                  </td>
                  <td style="width: 5%">{{ mailgun.queue_email_addeddate }}</td>
                  <td style="width: 10%">{{ mailgun.queue_email_to_email }}</td>
                  <td style="width: 10%">{{ mailgun.queue_email_subject }}</td>
                  <td style="width: 7%">
                    {{ mailgun.reason_message }}
                  </td>
                  <td style="width: 10%">{{ mailgun.queue_email_from_email }}</td>
                  <!-- <td style="width: 10%">{{ mailgun.queue_email_from_name }}</td> -->
                  <!-- <td style="width: 10%">{{ mailgun.queue_email_to_name }}</td> -->
                  <td style="width: 14%">
                    <div class="d-flex flex-column">
                      {{ mailgun.queue_email_client }}
                      <strong>{{ mailgun.queue_email_client_abbreviation }}</strong>
                    </div>
                  </td>
                  <td style="width: 14%">
                    <div class="d-flex flex-column">
                      {{ mailgun.queue_email_event }}
                      <strong>{{ mailgun.queue_email_event_name }}</strong>
                    </div>
                  </td>
                </tr>
              </tbody>

              <div class="mail-pagination">
                <InfiniteLoading @infinite="load" />
              </div>
            </table>
          </div>
        </div>
      </div>
    </template>

    <template v-if="smss.length > 0"
      >x
      <div class="row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive theme-scrollbar">
            <table class="table table-dashed">
              <thead>
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">To</th>
                  <th scope="col">From</th>
                  <th scope="col">Message</th>
                  <th scope="col">Code</th>
                  <th scope="col">Client</th>
                  <th scope="col">Event</th>
                  <th scope="col">Added Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sms, index) in smss" :key="`sms-${index}`">
                  <td>
                    <div class="d-flex gap-2">
                      <button
                        type="button"
                        @click.stop="handleEdit('sms', sms.ID, '')"
                        class="btn btn-sm btn-light text-dark btn-action"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-light text-dark btn-action"
                        @click.stop="handleResend('sms', sms.ID, '')"
                      >
                        Resend
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger text-white btn-action"
                        @click.stop="handleDelete('sms', sms.ID, '')"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span>{{ sms.queue_sms_to }}</span>
                      <span style="font-size: 10px">{{ sms.queue_sms_phone }}</span>
                    </div>
                  </td>
                  <td>{{ sms.queue_sms_from }}</td>
                  <td>{{ sms.queue_sms_message }}</td>
                  <td>{{ sms.queue_sms_code }}</td>
                  <td>{{ sms.queue_sms_client }}</td>
                  <td>{{ sms.queue_sms_event }}</td>
                  <td>{{ sms.queue_sms_AddedDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
}
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;
  &:hover {
    border: 1px solid #a20000;
  }
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>
<script lang="ts" setup>
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import {
  GetSystemDashboard,
  GetEmailQueue,
  GetSMSQueue,
  ResendEmailMailgun,
  ResendSMS,
  DeleteQueueEntry,
  GetMailGunFailedQueue,
  ResendEmailSendGrid,
  DeleteMailGunFailed,
} from "@/services/ApiCore";
import { defineAsyncComponent, onMounted, ref } from "vue";
import { openModal } from "@kolirt/vue-modal";
const ManageEntry = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/ManageEntry.vue")
);
const Registration = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Registration.vue")
);
const Email = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Email.vue")
);
const SMS = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/SMS.vue")
);
const Elavon = defineAsyncComponent(
  () => import("@/components/theme/dashboards/default/Elavon.vue")
);
import { useDashboardStore } from "@/store/dashboard";
const store = useDashboardStore();
import { useCommonStore } from "@/store/common";
const commonStore = useCommonStore();
import { toast } from "vue3-toastify";
let emails = ref<any>([]);
let smss = ref<any>([]);
let mailguns = ref<any>([]);
let currentPage = ref<number>(1);
onMounted(() => {
  (async () => {
    commonStore.toggleLoader();
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
});
function handleOnSMSQueue() {
  (async () => {
    emails.value = [];
    mailguns.value = [];
    smss.value = await GetSMSQueue();
  })();
}
function handleOnMailGunFailedQueue() {
  (async () => {
    smss.value = [];
    emails.value = [];
    currentPage.value = 1;
    mailguns.value = await GetMailGunFailedQueue(currentPage.value);
    currentPage.value = 2;
  })();
}

const load = async ($state: any) => {
  console.log("loading...");
  try {
    smss.value = [];
    emails.value = [];
    let rows = await GetMailGunFailedQueue(currentPage.value);
    mailguns.value.push(...rows);
    if (rows.length < 10) {
      $state.complete();
    } else {
      $state.loaded();
    }
    currentPage.value++;
  } catch (error) {
    $state.error();
  }
};
function handleOnEmailQueue() {
  (async () => {
    smss.value = [];
    mailguns.value = [];
    emails.value = await GetEmailQueue();
  })();
}
function handleOnReload() {
  (async () => {
    commonStore.toggleLoader();
    const res = await GetSystemDashboard();
    store.setDashboard(res);
  })();
}
function handleResend(type: string, ID: any, failed_ID: any) {
  (async () => {
    if (type == "sms") {
      await ResendSMS(ID);
      toast.success("Sent SMS ", {
        position: "top-right",
        autoClose: 2000,
      });
      handleOnSMSQueue();
    } else if (type == "email") {
      await ResendEmailMailgun(ID);
      toast.success("Sent Email ", {
        position: "top-right",
        autoClose: 2000,
      });
      handleOnEmailQueue();
    } else if (type == "email_mailgun" || type == "email_sendgrid") {
      if (type == "email_mailgun") {
        await DeleteMailGunFailed(failed_ID);
        await ResendEmailMailgun(ID);
        toast.success("Sent Email ", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (type == "email_sendgrid") {
        await DeleteMailGunFailed(failed_ID);
        await ResendEmailSendGrid(ID);
        toast.success("Sent Email ", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      handleOnMailGunFailedQueue();
    } else {
    }
  })();
}
function handleDelete(type: string, ID: any, failed_ID: any) {
  (async () => {
    await DeleteQueueEntry(type, ID);
    if (type == "sms") {
      handleOnSMSQueue();
    } else if (type == "email") {
      handleOnEmailQueue();
    } else {
    }
  })();
}
function handleEdit(type: string, ID: any, failed_ID: any) {
  openModal(ManageEntry, {
    type,
    ID,
    failed_ID,
  })
    // runs when modal is closed via confirmModal
    .then((data) => {
      console.log("success", data);
      if (data == "email") {
        handleOnEmailQueue();
      } else if (data == "sms") {
        handleOnSMSQueue();
      } else if (data == "email_failed") {
        handleOnMailGunFailedQueue();
      }
    })
    // runs when modal is closed via closeModal or esc
    .catch(() => {
      console.log("catch");
    });
}
</script>
