<template>
  <!-- Page Header -->
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Rules</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex justify-content-end">
          <!-- Button to open create rule modal -->
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click="handleCreate"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row">
              <div class="col-12">
                <!-- Table to display rules -->
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Rule</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Loop through rules data to populate table rows -->
                      <tr
                        v-for="(rule, index) in allRules"
                        :key="`rule-${index}`"
                      >
                        <td>
                          <div class="d-flex gap-2">
                            <!-- Edit Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click.stop="handleEdit(rule.rule_id)"
                            >
                              Edit
                            </button>
                            <!-- Delete Button -->
                            <button
                              type="button"
                              class="btn btn-sm btn-danger text-white btn-action"
                              @click.stop="handleDelete(rule.rule_id)"
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                        <!-- Display rule explanation text -->
                        <td v-html="rule.rule_explanation"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- Infinite Scrolling Component for Pagination -->
            <div class="mail-pagination" v-if="refreshRulePagination">
              <InfiniteLoading @infinite="loadRules" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>

<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { GetRules, ManageRules } from "@/services/ApiRules";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";

// Define refs to hold rule data and current pagination state
const allRules = ref<any[]>([]); // Stores all loaded rules
const currentPage = ref<number>(1); // Tracks current page for pagination
let refreshRulePagination = ref<boolean>(false);
// Import component for rule management modal
const ManageRule = defineAsyncComponent(
  () => import("@/components/modal/ManageRule.vue")
);

// Router instance for navigation if needed
const router = useRouter();

/**
 * Loads rules data with pagination.
 * @param $state - Infinite loading state to control component behavior
 */
const loadRules = async ($state: any) => {
  try {
    // Fetch paginated rules data
    const { rows, total } = await GetRules(currentPage.value);

    // Append new data to allRules array
    allRules.value.push(...rows);

    // If less than 25 rows are returned, we've reached the end
    if (rows.length < 25) {
      $state.complete(); // Indicate end of loading
    } else {
      $state.loaded(); // Allow loading more data
    }

    // Increment page for next request
    currentPage.value++;
  } catch (error) {
    // Handle error in loading data
    console.error("Error loading rules:", error);
    $state.error(); // Notify infinite loader of error
  }
};

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(ManageRule, {
    rule_id: "",
    edit_type: "create",
  });
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(rule_id: string) {
  openModal(ManageRule, { rule_id, edit_type: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      refreshRules();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(rule_id: string) {
  // Logic to delete rule goes here (e.g., call to API with rule_id)
  console.log("Deleting rule:", rule_id);
  await ManageRules(rule_id, "delete", "", "", "");
  // After deletion, refresh rules to reflect the change
  refreshRules();
}

/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function refreshRules() {
  allRules.value = []; // Clear existing rules data
  currentPage.value = 1; // Reset page counter
  refreshRulePagination.value = false;
  setTimeout(() => {
    refreshRulePagination.value = true;
  }, 200);
}
onMounted(() => {
  refreshRulePagination.value = false;
  setTimeout(() => {
    refreshRulePagination.value = true;
  }, 200);
});
</script>
