import ApiInstance from "./ApiBase";

export const GetToDos = async (type: string, keyword: any, filters: any): Promise<any> => {
    const filterKeys: string[] = Object.keys(filters);
    let queryArr: string[] = [];
    queryArr.push(`type=${type}`);
    queryArr.push(`keyword=${keyword}`);

    if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
            if (filters[key] != '') {
                const query = key + "=" + filters[key];
                queryArr.push(query);
            }
        });
    }

    let query = queryArr.length > 0 ? "?" + queryArr.join("&") : "";
    const res = await ApiInstance.get(`/todos${query}`);
    return res?.data;
};

export const GetToDo = async (id: any): Promise<any> => {
    const res = await ApiInstance.get(`/todo/${id}`);
    return res?.data;
};

export const ManageToDos = async (todo_id: any, todo_due: string, todo_description: string, action: string): Promise<any> => {
    const data = new FormData();
    data.append("todo_id", todo_id);
    data.append("todo_due", todo_due);
    data.append("action", action);
    data.append("todo_description", todo_description);
    const res = await ApiInstance.post(`/manage-todos`, data);
    return res?.data;
};
