import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "email-wrap email-main-wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xxl-12 col-xl-12 box-col-12" }
const _hoisted_5 = { class: "email-right-aside" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "table-responsive theme-scrollbar" }
const _hoisted_9 = { class: "table table-dashed" }
const _hoisted_10 = { class: "d-flex gap-2" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "mail-pagination"
}

import { defineAsyncComponent, onMounted, ref } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { GetRules, ManageRules } from "@/services/ApiRules";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";

// Define refs to hold rule data and current pagination state

export default /*@__PURE__*/_defineComponent({
  __name: 'IndexRules',
  setup(__props) {

const allRules = ref<any[]>([]); // Stores all loaded rules
const currentPage = ref<number>(1); // Tracks current page for pagination
let refreshRulePagination = ref<boolean>(false);
// Import component for rule management modal
const ManageRule = defineAsyncComponent(
  () => import("@/components/modal/ManageRule.vue")
);

// Router instance for navigation if needed
const router = useRouter();

/**
 * Loads rules data with pagination.
 * @param $state - Infinite loading state to control component behavior
 */
const loadRules = async ($state: any) => {
  try {
    // Fetch paginated rules data
    const { rows, total } = await GetRules(currentPage.value);

    // Append new data to allRules array
    allRules.value.push(...rows);

    // If less than 25 rows are returned, we've reached the end
    if (rows.length < 25) {
      $state.complete(); // Indicate end of loading
    } else {
      $state.loaded(); // Allow loading more data
    }

    // Increment page for next request
    currentPage.value++;
  } catch (error) {
    // Handle error in loading data
    console.error("Error loading rules:", error);
    $state.error(); // Notify infinite loader of error
  }
};

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(ManageRule, {
    rule_id: "",
    edit_type: "create",
  });
}

/**
 * Opens modal for editing an existing rule.
 * @param rule_id - ID of the rule to edit
 */
function handleEdit(rule_id: string) {
  openModal(ManageRule, { rule_id, edit_type: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      refreshRules();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

/**
 * Handle deleting a rule.
 * @param rule_id - ID of the rule to delete
 */
async function handleDelete(rule_id: string) {
  // Logic to delete rule goes here (e.g., call to API with rule_id)
  console.log("Deleting rule:", rule_id);
  await ManageRules(rule_id, "delete", "", "", "");
  // After deletion, refresh rules to reflect the change
  refreshRules();
}

/**
 * Refreshes the rules list by resetting pagination and clearing data.
 */
function refreshRules() {
  allRules.value = []; // Clear existing rules data
  currentPage.value = 1; // Reset page counter
  refreshRulePagination.value = false;
  setTimeout(() => {
    refreshRulePagination.value = true;
  }, 200);
}
onMounted(() => {
  refreshRulePagination.value = false;
  setTimeout(() => {
    refreshRulePagination.value = true;
  }, 200);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { class: "container-fluid" }, [
      _createElementVNode("div", { class: "page-title" }, [
        _createElementVNode("div", { class: "row" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-6 col-12" }, [
            _createElementVNode("h2", null, "Rules")
          ], -1)),
          _createElementVNode("div", { class: "col-sm-6 col-12 d-flex justify-content-end" }, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-md btn-primary text-light",
              onClick: handleCreate
            }, " Create ")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("table", _hoisted_9, [
                      _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", { scope: "col" }),
                          _createElementVNode("th", { scope: "col" }, "Rule")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allRules.value, (rule, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: `rule-${index}`
                          }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-light text-dark btn-action",
                                  onClick: _withModifiers(($event: any) => (handleEdit(rule.rule_id)), ["stop"])
                                }, " Edit ", 8, _hoisted_11),
                                _createElementVNode("button", {
                                  type: "button",
                                  class: "btn btn-sm btn-danger text-white btn-action",
                                  onClick: _withModifiers(($event: any) => (handleDelete(rule.rule_id)), ["stop"])
                                }, " Delete ", 8, _hoisted_12)
                              ])
                            ]),
                            _createElementVNode("td", {
                              innerHTML: rule.rule_explanation
                            }, null, 8, _hoisted_13)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ]),
              (_unref(refreshRulePagination))
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_unref(InfiniteLoading), { onInfinite: loadRules })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})