<template>
  <!-- Page Header -->
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>Disputes</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex gap-2 justify-content-end">
          <!-- Button to open create rule modal -->
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click="handleCreate"
          >
            Create Dispute
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row">
              <div class="col-12">
                <!-- Table to display rules -->
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Case Number</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Name</th>
                        <th scope="col">Client</th>
                        <th scope="col">Event</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Loop through rules data to populate table rows -->
                      <tr
                        v-for="(dispute, index) in allDisputes"
                        :key="`dispute-${index}`"
                      >
                        <td>
                          <div class="d-flex gap-2">
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click="handleView(dispute.ID)"
                            >
                              View
                            </button>
                          </div>
                        </td>
                        <!-- Display rule explanation text -->
                        <td>{{ dispute.case_number }}</td>
                        <td class="items-center align-top"></td>
                        <td class="items-center align-top"></td>
                        <td>{{ dispute.name }}</td>
                        <td>{{ dispute.client }}</td>
                        <td class="items-center align-top">{{ dispute.event }}</td>
                        <td class="items-right align-top">
                          {{ $filters.currency(dispute.amount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-text {
  font-size: 28px;
}
.animate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>

<script lang="ts" setup>
import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetDisputes } from "@/services/ApiDispute";
// Define refs to hold rule data and current pagination state
const allDisputes = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const CreateDispute = defineAsyncComponent(
  () => import("@/components/modal/CreateDispute.vue")
);
// Router instance for navigation if needed
const router = useRouter();

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(CreateDispute, {})
    .then(() => {
      // Reload rules after editing to reflect changes
      loadDisputes();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}
function handleView(ID: any) {
  router.push(`/app/disputes/view/${ID}`);
}

function loadDisputes() {
  (async () => {
    try {
      const { rows } = await GetDisputes("all", 1, {});
      allDisputes.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadDisputes();
});
</script>
