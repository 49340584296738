<template>
  <!-- Page Header -->
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-sm-6 col-12">
          <h2>To Dos</h2>
        </div>
        <div class="col-sm-6 col-12 d-flex gap-2 justify-content-end">
          <!-- Button to open create rule modal -->
          <button
            type="button"
            class="btn btn-md btn-primary text-light"
            @click="handleCreate"
          >
            Create To Do
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Content Area -->
  <div class="container-fluid">
    <div class="email-wrap email-main-wrapper">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 box-col-12">
          <div class="email-right-aside">
            <div class="row">
              <div class="col-12">
                <!-- Table to display rules -->
                <div class="table-responsive theme-scrollbar">
                  <table class="table table-dashed">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">To Do</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Loop through rules data to populate table rows -->
                      <tr v-for="(todo, index) in allToDos" :key="`todo-${index}`">
                        <td>
                          <div class="d-flex gap-2">
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click="handleView(todo.todo_id)"
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click="handleDelete(todo.todo_id)"
                            >
                              Delete
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-light text-dark btn-action"
                              @click="handleDone(todo.todo_id)"
                            >
                              Done
                            </button>
                          </div>
                        </td>
                        <td>{{ todo.todo_description }}</td>
                        <td>{{ formatDate(todo.todo_DueDate) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-text {
  font-size: 28px;
}
.animate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Custom styles for action buttons */
.btn-action {
  white-space: nowrap;
  border: 1px solid #a20000;

  &:hover,
  &:focus {
    border: 1px solid #a20000;
  }
}
</style>

<script lang="ts" setup>
import VueMultiselect from "vue-multiselect";
import { defineAsyncComponent, onMounted, ref } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { openModal } from "@kolirt/vue-modal";
import { useRouter } from "vue-router";
import { GetToDos, ManageToDos } from "@/services/ApiToDos";
import dayjs from "dayjs";
// Define refs to hold rule data and current pagination state
const allToDos = ref<any[]>([]); // Stores all loaded rules

// Import component for rule management modal
const CreateToDo = defineAsyncComponent(
  () => import("@/components/modal/CreateToDo.vue")
);
// Router instance for navigation if needed
const router = useRouter();

function formatDate(dateString: string) {
  const date = dayjs(dateString);
  // Then specify how you want your dates to be formatted
  return date.format("dddd MMMM D, YYYY");
}

/**
 * Opens modal for creating a new rule.
 */
function handleCreate() {
  openModal(CreateToDo, { todo_id: "", todo_action: "NEW" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadToDos();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

function handleView(todo_id: any) {
  openModal(CreateToDo, { todo_id, todo_action: "update" })
    .then(() => {
      // Reload rules after editing to reflect changes
      loadToDos();
    })
    .catch(() => {
      console.log("Edit modal closed without saving.");
    });
}

function handleDone(todo_id: any) {
  (async () => {
    try {
      await ManageToDos(todo_id, "", "", "close");
      loadToDos();
    } catch (error) {
      console.log("error", error);
    }
  })();
}

function handleDelete(todo_id: any) {
  (async () => {
    try {
      await ManageToDos(todo_id, "", "", "delete");
      loadToDos();
    } catch (error) {
      console.log("error", error);
    }
  })();
}

function loadToDos() {
  (async () => {
    try {
      const { rows } = await GetToDos("all", 1, {});
      allToDos.value = rows;
    } catch (error) {
      console.log("error", error);
    }
  })();
}
onMounted(() => {
  loadToDos();
});
</script>
